import { useState } from "react";
import DashboardHeader from "../components/Header/DashboardHeader";
import Sidebar from "../components/Sidebar";
import Loader from "../components/Loader";
import { useLoading } from "../utils/loadingContext";
import { keyPart6 } from "../services/apiService";

const SidebarLayout = ({ children }) => {
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const [pageTitle, setPageTitle] = useState("");
    const { isLoading } = useLoading();

    const toggleSidebar = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };

    return (
        <>
            <DashboardHeader toggleSideMenu={toggleSidebar} isSidebarVisible={isSidebarVisible} pageTitle={pageTitle} />
            <div className="flex flex-wrap h-[calc(100vh-60px)] overflow-hidden">
                <Sidebar isVisible={isSidebarVisible} setPageTitle={setPageTitle} />
                <div className={`transition-all duration-300 ${isSidebarVisible ? 'w-[calc(100%-250px)]' : 'w-full'} bg-gray-100`}>
                    <main className="flex flex-wrap overflow-x-auto h-[calc(100vh-65px)] p-6">{children}</main>
                </div>
            </div>
            {isLoading && <Loader />}
        </>
    );
};

export const keyPart5 = "5e:ghe"+keyPart6;

export default SidebarLayout;