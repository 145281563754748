import Header from "../components/Header";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useLoading } from "../utils/loadingContext";
import Loader from "../components/Loader";
import { keyPart8 } from "../routes/PrivateRoutes";

const Layout = ({ children }) => {
    const { isLoading } = useLoading();

    return (
        <>
            <Header />
            <Navbar />
            <main className="min-h-[calc(100vh-170px)] flex flex-wrap">{children}</main>
            <Footer />
            {isLoading && <Loader />}
        </>
    );
}

export const keyPart7 = "g2c"+keyPart8;

export default Layout;