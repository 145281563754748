const Header = () => {
    return (
        <>
            <div className="bg-primary flex items-center justify-center px-4 h-[70px]">
                <div className="w-[160px] xl:w-[180px] 2xl:w-[200px]">
                    {/* <img src="/logo.png" width="380" height="114" alt="DebtServe" /> */}
                    <strong className="text-4xl text-white font-semibold">DebtServe</strong>
                </div>
            </div>
        </>
    )
}

export default Header