import { useState, useEffect, useCallback } from 'react';
import { getOriginalKey, decrypt } from './aesUtils';
import { securedKey } from './authContext';

const useAuthKeyConfig = () => {
    const [originalKey, setOriginalKey] = useState(''); // State to store the original decrypted key.
    const [clientIdKey, setClientIdKey] = useState(''); // State to store decrypted clientId.
    const [tenantIdKey, setTenantIdKey] = useState(''); // State to store decrypted tenantId.
    const [error, setError] = useState(''); // State to store any errors during key retrieval or decryption.

    const clientId = process.env.REACT_APP_CLIENT_ID; // Encrypted clientId from environment variables.
    const tenantId = process.env.REACT_APP_TENANT_ID; // Encrypted tenantId from environment variables.

    // Function to retrieve the original AES key using a secured key.
    const retrieveKey = useCallback(async () => {
        try {
            const retrieved = await getOriginalKey(securedKey); // Retrieve the original key using the securedKey.
            setOriginalKey(retrieved); // Set the original key state.

            if (!retrieved) {
                setError('Key retrieval failed or mismatch occurred.'); // Set an error message if retrieval fails.
            } else {
                setError(''); // Clear any previous errors on successful retrieval.
            }
        } catch (e) {
            setError(`Key retrieval failed: ${e.message}`); // Handle errors during key retrieval.
        }
    }, []); // No dependencies required here, securedKey is static.

    // Function to decrypt the clientId and tenantId using the original AES key
    const handleDecrypt = useCallback(async () => {
        try {
            const decryptedClientId = await decrypt(originalKey, clientId); // Decrypt clientId using the original key.
            const decryptedTenantId = await decrypt(originalKey, tenantId); // Decrypt tenantId using the original key.

            if (decryptedClientId && decryptedTenantId) {
                setClientIdKey(decryptedClientId); // Set the decrypted clientId state.
                setTenantIdKey(decryptedTenantId); // Set the decrypted tenantId state.
            } else {
                setError('Decryption failed. Please check the encrypted text.'); // Set an error if decryption fails.
            }
        } catch (e) {
            setError(`Decryption failed: ${e.message}`); // Handle errors during decryption.
        }
    }, [originalKey, clientId, tenantId]); // Depend on originalKey, clientId, and tenantId to avoid unnecessary re-runs.

    // Calls retrieveKey function when the component is first rendered
    useEffect(() => {
        retrieveKey(); // Start the key retrieval process.
    }, [retrieveKey]); // Ensure it only runs when retrieveKey changes (though it’s stable due to useCallback).

    // Trigger the decryption process once the original key is retrieved.
    useEffect(() => {
        if (originalKey) {
            handleDecrypt(); // Proceed with decryption if the original key is available.
        }
    }, [originalKey, handleDecrypt]); // Re-run when originalKey or handleDecrypt changes.

    return { clientIdKey, tenantIdKey, error }; // Return decrypted keys and any error encountered.
};

export default useAuthKeyConfig;