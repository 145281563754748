import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const fetchConfig = async () => {
  try {
    const response = await fetch('/config.json');
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const config = await response.json();

    //console.log('Config:', config); // Debug: Check config

    // Update document title
    document.title = config.title || 'DebtServe';

    // Update meta description
    let metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.content = config.description || 'Default description';
    } else {
      metaDescription = document.createElement('meta');
      metaDescription.name = 'description';
      metaDescription.content = config.description || 'Default description';
      document.head.appendChild(metaDescription);
    }
  } catch (error) {
    console.error('Error fetching config:', error);
  }
};

// Ensure configuration is loaded before rendering the app
fetchConfig().then(() => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
});

// Measure performance in the app
reportWebVitals();