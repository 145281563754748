import { useState, useEffect, useRef, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../utils/authContext";
import apiService from '../../services/apiService';

const Sidebar = ({ isVisible, setPageTitle }) => {
    const { account, login, logout } = useAuth(); // Get authentication details from context
    const location = useLocation(); // Get current location to highlight active links
    const [menuItems, setMenuItems] = useState([]); // Store fetched menu items
    const fetchedRef = useRef(false); // Prevent multiple API calls after the first fetch

    // Function to determine if the link is active based on the current path
    const isActiveLink = useCallback((path) => {
        const currentPath = location.pathname.endsWith('/') 
            ? location.pathname.slice(0, -1) // Remove trailing slash for consistency
            : location.pathname;
        return currentPath === path;
    }, [location.pathname]);

    // Fetch menu items from API on initial render
    useEffect(() => {
        // Check if the menu items have already been fetched
        if (fetchedRef.current) return;

        const fetchMenuItems = async () => {
            try {
                // Fetch menu items from API (replace with actual API URL)
                const response = await apiService.get("/dashboard_menu.json");
                const { menus } = response.data.payload; // Extract menu data from response payload
                setMenuItems(menus); // Set menu items in state
            } catch (error) {
                console.error("Failed to fetch menu items", error); // Handle API fetch error
            }
        };

        fetchMenuItems();
        fetchedRef.current = true; // Mark as fetched to avoid re-fetching
    }, []);

    // Update active menu and page title based on the current path
    useEffect(() => {
        const allMenuItems = [
            { name: "Home", path: "/" },
            ...menuItems,
            { name: "DMC", path: "/dashboard/dmc" },
            { name: "Plan Summary", path: "/dashboard/plan-summary" },
            { name: "Commercial Outcomes", path: "/dashboard/commercial-outcomes" },
            { name: "Customers", path: "/dashboard/customers" },
            { name: "Customers 2", path: "/dashboard/customers2" },
        ];
        const currentMenu = allMenuItems.find(item => isActiveLink(item.path)); // Find the active menu item
        
        if (currentMenu && currentMenu.name) {
            setPageTitle(currentMenu.name); // Update page title with active menu name
        } else {
            setPageTitle("Dashboard"); // Fallback title if no match is found
        }
    }, [location.pathname, menuItems, setPageTitle, isActiveLink]); // Run this effect whenever location or menuItems change

    return (
        <div className={`bg-primary flex flex-wrap flex-col items-start justify-start min-h-screen transition-all duration-300 ${
            isVisible ? 'w-[250px] translate-x-0' : 'opacity-0 -translate-x-full w-0 overflow-hidden'
        }`}>
            {/* Sidebar container with transition based on visibility */}
            <div className="w-full overflow-x-auto max-h-[calc(100vh-65px)]">
                {/* Navigation section */}
                <nav className="text-white">
                    <ul className="flex flex-wrap flex-col">
                        <li>
                            <Link to="/" className={`block px-4 py-3 hover:text-white transition-all duration-500 ${isActiveLink('/') ? 'bg-primary-300/95 hover:bg-primary-300' : 'hover:bg-primary-200'}`}>Home</Link>
                        </li>
                        {/* Dynamic menu items fetched from API */}
                        {menuItems.map(item => (
                            <li key={item.name}>
                                <Link 
                                    to={item.path} 
                                    className={`block px-4 py-3 hover:text-white transition-all duration-500 ${isActiveLink(item.path) ? 'bg-primary-300/95 hover:bg-primary-300' : 'hover:bg-primary-200'}`}
                                >
                                    {item.name} {/* Display the name of the menu item */}
                                </Link>
                            </li>
                        ))}
                        <li>
                            <Link to="/dashboard/dmc" className={`block px-4 py-3 hover:text-white transition-all duration-500 ${isActiveLink('/dashboard/dmc') ? 'bg-primary-300/95 hover:bg-primary-300' : 'hover:bg-primary-200'}`}>DMC</Link>
                        </li>
                        <li>
                            <Link to="/dashboard/plan-summary" className={`block px-4 py-3 hover:text-white transition-all duration-500 ${isActiveLink('/dashboard/plan-summary') ? 'bg-primary-300/95 hover:bg-primary-300' : 'hover:bg-primary-200'}`}>Plan Summary</Link>
                        </li>
                        <li>
                            <Link to="/dashboard/commercial-outcomes" className={`block px-4 py-3 hover:text-white transition-all duration-500 ${isActiveLink('/dashboard/commercial-outcomes') ? 'bg-primary-300/95 hover:bg-primary-300' : 'hover:bg-primary-200'}`}>Commercial Outcomes</Link>
                        </li>
                        <li>
                            <Link to="/dashboard/customers" className={`block px-4 py-3 hover:text-white transition-all duration-500 ${isActiveLink('/dashboard/customers') ? 'bg-primary-300/95 hover:bg-primary-300' : 'hover:bg-primary-200'}`}>Customers</Link>
                        </li>
                        <li>
                            <Link to="/dashboard/customers2" className={`block px-4 py-3 hover:text-white transition-all duration-500 ${isActiveLink('/dashboard/customers2') ? 'bg-primary-300/95 hover:bg-primary-300' : 'hover:bg-primary-200'}`}>Customers 2</Link>
                        </li>
                        {/* Authentication section: login or logout based on user state */}
                        <li>
                            {account ? (
                                <Link onClick={logout} className="block px-4 py-3 transition-all duration-500 hover:bg-red-400 hover:text-white">Logout</Link>
                            ) : (
                                <Link onClick={login} className="block px-4 py-3 hover:bg-primary-200 hover:text-white transition-all duration-500">Login</Link>
                            )}
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default Sidebar;