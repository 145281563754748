
import { useLoading } from "../utils/loadingContext";
import Loader from "../components/Loader";
import { keyPart5 } from "./SidebarLayout";

const EmptyLayout = ({ children }) => {
    const { isLoading } = useLoading();

    return (
        <>
            {children}
            {isLoading && <Loader />}
        </>
    );
}

export const keyPart4 = "e7"+keyPart5;

export default EmptyLayout;