import React, { createContext, useState, useContext } from 'react';

// Create a context to store loading information
const LoadingContext = createContext();

/**
 * LoadingProvider component gives access to loading state for the entire app.
 * It lets us know if something is loading (isLoading) and allows us to change it (setIsLoading).
 * 
 * @param {React.ReactNode} children - The components that will use the loading info
 */
export const LoadingProvider = ({ children }) => {
    // State to keep track of whether something is loading
    const [isLoading, setIsLoading] = useState(false);

    return (
        // Provide the loading state (isLoading) and the function to change it (setIsLoading) to all children components
        <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
            {children}
        </LoadingContext.Provider>
    );
};

/**
 * useLoading is a simple way for any component to access the loading state.
 * Instead of passing props, we can just use this to know if something is loading.
 * 
 * @returns {Object} - Contains the loading state (isLoading) and function to change it (setIsLoading)
 */
export const useLoading = () => useContext(LoadingContext);