import { Link } from "react-router-dom";
import { useAuth } from "../../utils/authContext";

const DashboardHeader = ({ toggleSideMenu, isSidebarVisible, pageTitle }) => {
    const { account, logout } = useAuth(); // Get account and logout functionality from the custom auth context

    return (
        <>
            {/* Main header container with background and height */}
            <div className="bg-primary-50 h-[60px] flex items-center justify-start relative">
                {/* Sidebar toggle and branding section */}
                <div className={`w-[250px] px-4 h-full flex flex-wrap items-center transition-all duration-300 ${isSidebarVisible && 'bg-primary-600 text-white'}`}>
                    {/* Button to toggle the sidebar */}
                    <button onClick={toggleSideMenu}>
                        <svg className={`text-primary ${isSidebarVisible && 'text-white'}`} xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24">
                            <line x1="3" y1="6" x2="21" y2="6" stroke="currentColor" strokeWidth="2" className={`transform-origin-center transition-transform duration-300 ${isSidebarVisible ? "rotate-45 translate-y-[0] translate-x-[8px]" : ""}`} />
                            <line x1="3" y1="12" x2="21" y2="12" stroke="currentColor" strokeWidth="2" className={`transition-opacity duration-300 ${isSidebarVisible ? "opacity-0" : "opacity-100"}`} />
                            <line x1="3" y1="18" x2="21" y2="18" stroke="currentColor" strokeWidth="2" className={`transform-origin-center transition-transform duration-300 ${isSidebarVisible ? "-rotate-45 translate-y-[8px] translate-x-[-9px]" : ""}`} />
                        </svg>
                    </button>
                    {/* Brand name displayed next to the sidebar toggle */}
                    <strong className="text-4xl font-semibold ml-2">DebtServe</strong>
                </div>

                {/* Title section in the middle of the header */}
                <div className="absolute top-0 left-0 w-full h-full flex flex-wrap items-center justify-center pointer-events-none">
                    <h1 className="text-2xl font-semibold inline">{pageTitle}</h1>
                </div>

                {/* User account section on the right side of the header */}
                <div className="w-[calc(100%-250px)] flex justify-end px-4 relative">
                    <div className="relative flex group">
                        {/* Button to open the dropdown */}
                        <button className="flex items-center p-2 focus:outline-none">
                            <svg width="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-gray-700">
                                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
                                <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                            </svg>
                        </button>

                        {/* Dropdown menu - only visible when hovered */}
                        <div className="absolute right-0 top-full z-[-1] before:absolute before:top-[-10px] before:right-[12px] before:w-0 before:h-0 before:content-[''] before:border-l-[6px] before:border-r-[6px] before:border-b-[10px] before:border-l-transparent before:border-r-transparent before:border-b-white mt-2 w-48 bg-white rounded shadow-lg border border-gray-200 opacity-0 group-hover:z-20  group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                            <ul>
                                {account && (
                                    <>
                                        <li className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                                            {account?.name}
                                        </li>
                                        <li className="block px-4 py-2 text-gray-700 hover:bg-red-400 hover:text-white">
                                            <Link onClick={logout} className="flex items-center h-full">Logout</Link>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DashboardHeader;