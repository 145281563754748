import { useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../utils/authContext";

const Navbar = () => {
    const { account, login, logout } = useAuth(); // Get authentication details from the custom auth context

    const location = useLocation(); // Get current location to highlight active links    

    // Function to determine if the link is active based on the current path
    const isActiveLink = useCallback((path) => {
        const currentPath = location.pathname.endsWith('/') 
            ? location.pathname.slice(0, -1) // Remove trailing slash for consistency
            : location.pathname;
        return currentPath === path;
    }, [location.pathname]);



    return (
        <nav className="bg-gray-800 px-4 text-white h-[50px] flex flex-wrap">
            <ul className="flex flex-wrap items-center h-full space-x-4">
                <li className="h-full">
                    <Link to="/" className={`flex items-center h-full transition-all duration-500 ${isActiveLink('/') ? 'bg-primary-200 px-4' : 'hover:text-primary-100'}`}>Home</Link>
                </li>
                <li className="h-full">
                    <Link to="/upload" className={`flex items-center h-full transition-all duration-500 ${isActiveLink('/upload') ? 'bg-primary-200 px-4' : 'hover:text-primary-100'}`}>Upload</Link>
                </li>
                <li className="h-full">
                    <Link to="/dashboard" className={`flex items-center h-full transition-all duration-500 ${isActiveLink('/dashboard') ? 'bg-primary-200 px-4' : 'hover:text-primary-100'}`}>Dashboard</Link>
                </li>
                <li className="h-full">
                    <Link to="/balance-request" className={`flex items-center h-full transition-all duration-500 ${isActiveLink('/balance-request') ? 'bg-primary-200 px-4' : 'hover:text-primary-100'}`}>Balance Request</Link>
                </li>
                <li className="h-full">
                    <Link to="/proposals" className={`flex items-center h-full transition-all duration-500 ${isActiveLink('/proposals') ? 'bg-primary-200 px-4' : 'hover:text-primary-100'}`}>Proposals</Link>
                </li>
                <li className="h-full">
                    <Link to="/flagged-proposals" className={`flex items-center h-full transition-all duration-500 ${isActiveLink('/flagged-proposals') ? 'bg-primary-200 px-4' : 'hover:text-primary-100'}`}>Flagged Proposals</Link>
                </li>
                {/* Authentication links: either login or logout based on the user's authentication status */}
                <li className="h-full">
                    {account ? (
                        <Link onClick={logout} className={`flex items-center h-full transition-all duration-500 ${isActiveLink('/') ? 'bg-primary-200 px-4' : 'hover:text-primary-100'}`}>Logout</Link>
                    ) : (
                        <Link onClick={login} className={`flex items-center h-full transition-all duration-500 ${isActiveLink('/') ? 'bg-primary-200 px-4' : 'hover:text-primary-100'}`}>Login</Link>
                    )}
                </li>
            </ul>
        </nav>
    );
}

export default Navbar;