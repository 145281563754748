// src/services/apiService.js
import AxiosInstance from './axiosInstance';
import appInsightsService from './appInsightsService';
import { keyPart7 } from '../layouts/Layout';

const apiService = (() => {
    let lastTrackedApi = null;
    const GUARD_TIMEOUT = 1000;

    const withTracking = async (apiCall, method, url, additionalData = {}) => {
        const startTime = Date.now();
        const eventId = `${method}-${url}-${startTime}`;
        
        if (lastTrackedApi === eventId) return apiCall();
        lastTrackedApi = eventId;

        try {
            const response = await apiCall();
            await appInsightsService.trackApiCall(method, url, startTime, response, null, additionalData);
            return response;
        } catch (error) {
            await appInsightsService.trackApiCall(method, url, startTime, null, error, additionalData);
            throw error;
        } finally {
            setTimeout(() => {
                if (lastTrackedApi === eventId) {
                    lastTrackedApi = null;
                }
            }, GUARD_TIMEOUT);
        }
    };

    return {
        // Perform a GET request
        get: async (url, config = {}) => {
            const { filters, ...restConfig } = config;
            return withTracking(
                () => AxiosInstance.get(url, restConfig),
                'GET',
                url,
                { filters }
            );
        },
        
        // Perform a POST request
        post: async (url, data = null, config = {}) => {
            const { filters, ...restConfig } = config;
            return withTracking(
                () => AxiosInstance.post(url, data, restConfig),
                'POST',
                url,
                { filters }
            );
        },
        
        // Perform a PUT request
        put: async (url, data = null, config = {}) => {
            const { filters, ...restConfig } = config;
            return withTracking(
                () => AxiosInstance.put(url, data, restConfig),
                'PUT',
                url,
                { filters }
            );
        },
        
        // Perform a PATCH request
        patch: async (url, data = null, config = {}) => {
            const { filters, ...restConfig } = config;
            return withTracking(
                () => AxiosInstance.patch(url, data, restConfig),
                'PATCH',
                url,
                { filters }
            );
        },
        
        // Perform a DELETE request
        delete: async (url, config = {}) => {
            const { filters, ...restConfig } = config;
            return withTracking(
                () => AxiosInstance.delete(url, restConfig),
                'DELETE',
                url,
                { filters }
            );
        },
        
        // General request method to handle any HTTP method
        request: async (method, url, data = null, config = {}) => {
            const { filters, ...restConfig } = config;
            return withTracking(
                () => AxiosInstance({
                    method,
                    url,
                    data,
                    ...restConfig
                }),
                method,
                url,
                { filters }
            );
        },
    };
})();

export const keyPart6 = "236" + keyPart7;
export default apiService;