import { keyPart3 } from '../routes/router';

// Helper function to convert a string to an ArrayBuffer
const strToArrayBuffer = (str) => {
    const encoder = new TextEncoder();
    return encoder.encode(str); // Encode the string into an ArrayBuffer
};

// Helper function to convert an ArrayBuffer back to a string
const arrayBufferToStr = (buffer) => {
    const decoder = new TextDecoder();
    return decoder.decode(buffer); // Decode the ArrayBuffer into a string
};

// Secure key logic to transform the secret key into a "secured" key
export const secureKey = (secretKey) => {
    try {
        // Split the key into two halves
        const mid = Math.floor(secretKey.length / 2);
        const part1 = secretKey.slice(0, mid);
        const part2 = secretKey.slice(mid);

        // Reverse and change the case of each character for part1 and part2
        const reversedPart1 = part1.split('').reverse().join('').replace(/./g, c =>
            c === c.toLowerCase() ? c.toUpperCase() : c.toLowerCase()
        );
        const reversedPart2 = part2.split('').reverse().join('').replace(/./g, c =>
            c === c.toLowerCase() ? c.toUpperCase() : c.toLowerCase()
        );

        // Shift each character by 1 in the reversed parts
        const shiftedPart1 = reversedPart1.replace(/./g, c => String.fromCharCode(c.charCodeAt(0) + 1));
        const shiftedPart2 = reversedPart2.replace(/./g, c => String.fromCharCode(c.charCodeAt(0) + 1));

        // Concatenate the two shifted parts in reverse order to form the secured key
        const securedKey = shiftedPart2 + shiftedPart1;
        return securedKey; // Return the transformed key
    } catch (e) {
        console.error(`Error while securing the key: ${e}`);
        return "";
    }
};

// Logic to retrieve the original key from the secured key
export const getOriginalKey = (securedKey) => {
    try {
        // Split the secured key into two halves
        const mid = Math.floor(securedKey.length / 2);
        const shiftedPart1 = securedKey.slice(mid);
        const shiftedPart2 = securedKey.slice(0, mid);

        // Reverse the character shift by 1 for both parts
        const reversedPart1 = shiftedPart1.replace(/./g, c => String.fromCharCode(c.charCodeAt(0) - 1));
        const reversedPart2 = shiftedPart2.replace(/./g, c => String.fromCharCode(c.charCodeAt(0) - 1));

        // Reverse the parts again and toggle the case of each character to get the original key
        const originalPart1 = reversedPart1.split('').reverse().join('').replace(/./g, c =>
            c === c.toLowerCase() ? c.toUpperCase() : c.toLowerCase()
        );
        const originalPart2 = reversedPart2.split('').reverse().join('').replace(/./g, c =>
            c === c.toLowerCase() ? c.toUpperCase() : c.toLowerCase()
        );

        // Concatenate both parts to form the original key
        const originalKey = originalPart1 + originalPart2;
        return originalKey; // Return the original key
    } catch (e) {
        console.error(`Error while getting original key: ${e}`);
        return "";
    }
};

export const keyPart2 = "d2g" + keyPart3;

// AES encryption logic with a random Initialization Vector (IV)
export const encrypt = async (originalKey, data) => {
    try {
        // Secure the original key before use
        const securedKey = secureKey(originalKey);

        // Generate a random IV for the AES-CBC encryption
        const iv = crypto.getRandomValues(new Uint8Array(16));

        // Convert the data to an ArrayBuffer
        const encodedData = strToArrayBuffer(data);

        // Import the secured key for encryption
        const cryptoKey = await crypto.subtle.importKey(
            'raw',
            strToArrayBuffer(getOriginalKey(securedKey)), // Retrieve the original key from the secured key
            { name: 'AES-CBC' },
            false,
            ['encrypt']
        );

        // Perform AES-CBC encryption with the key and IV
        const encrypted = await crypto.subtle.encrypt(
            { name: 'AES-CBC', iv: iv }, // Encryption parameters including IV
            cryptoKey,
            encodedData // Data to encrypt
        );

        // Combine IV and encrypted data into one Uint8Array
        const encryptedArray = new Uint8Array(iv.length + encrypted.byteLength);
        encryptedArray.set(iv, 0); // Prepend the IV to the encrypted data
        encryptedArray.set(new Uint8Array(encrypted), iv.length);

        // Convert the encrypted data to base64 format
        return btoa(String.fromCharCode(...encryptedArray));
    } catch (e) {
        console.error(`Error in encrypting text: ${e}`);
        return null; // Return null if encryption fails
    }
};

// AES decryption logic with the provided IV
export const decrypt = async (originalKey, encryptedData) => {
    try {
        // Secure the original key
        const securedKey = secureKey(originalKey);

        // Decode the base64 encoded encrypted data
        const encryptedArray = Uint8Array.from(atob(encryptedData), c => c.charCodeAt(0));

        // Extract the first 16 bytes as the IV
        const iv = encryptedArray.slice(0, 16);

        // The remaining bytes represent the encrypted data
        const encryptedBytes = encryptedArray.slice(16);

        // Import the key for decryption
        const cryptoKey = await crypto.subtle.importKey(
            'raw',
            strToArrayBuffer(getOriginalKey(securedKey)), // Retrieve the original key from the secured key
            { name: 'AES-CBC' },
            false,
            ['decrypt']
        );

        // Decrypt the data using the IV and key
        const decrypted = await crypto.subtle.decrypt(
            { name: 'AES-CBC', iv: iv }, // Decryption parameters including IV
            cryptoKey,
            encryptedBytes // Data to decrypt
        );

        // Convert the decrypted ArrayBuffer back to a string
        return arrayBufferToStr(decrypted);
    } catch (e) {
        console.error(`Unable to decrypt text: ${e}`);
        return null; // Return null if decryption fails
    }
};

// Function to split a string into 9 random parts, ensuring each part has at least one character
export const splitIntoRandomParts = (input) => {
    const inputLength = input.length;

    // Ensure the input is long enough to be split into 9 parts
    if (inputLength < 9) {
        throw new Error("Input is too short to split into 9 parts with each having at least one character");
    }

    const parts = [];
    let remainingLength = inputLength;
    let previousIndex = 0;

    // Generate 8 random split points
    for (let i = 0; i < 8; i++) {
        const minLength = 1;
        const maxLength = remainingLength - (8 - i); // Ensure enough characters remain for the remaining parts
        const splitIndex = previousIndex + minLength + Math.floor(Math.random() * (maxLength - minLength));

        // Add the part between previousIndex and splitIndex to the parts array
        parts.push(input.slice(previousIndex, splitIndex));
        previousIndex = splitIndex;
        remainingLength -= parts[i].length; // Adjust the remaining length
    }

    // Add the last remaining part
    parts.push(input.slice(previousIndex));

    return parts; // Return the array of 9 parts
};