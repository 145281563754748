import React, { createContext, useContext, useEffect, useState, Suspense } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import useAuthKeyConfig from "./authConfig";
import { keyPart2 } from "./aesUtils";
import appInsightsService from "../services/appInsightsService";

// Create a Context for authentication data
const AuthContext = createContext();

// Get the default page from environment variable or use "/" as fallback
const DEFAULT_LOGIN_REDIRECT = process.env.REACT_APP_DEFAULT_PAGE_AFTER_LOGIN || "/";

// Custom hook to use the authentication context
export const useAuth = () => useContext(AuthContext);

// Export a constant for secure key handling
export const securedKey = "c9g24" + keyPart2;

export const AuthProvider = ({ children, defaultLoginRedirect=DEFAULT_LOGIN_REDIRECT }) => {
    // Destructure authentication keys and potential error from the hook
    const { clientIdKey, tenantIdKey, error: keyError } = useAuthKeyConfig();

    // State variables to manage authentication and MSAL instance
    const [account, setAccount] = useState(null); // Stores the authenticated user account
    const [isInitialized, setIsInitialized] = useState(false); // Indicates if MSAL is initialized
    const [isLoading, setIsLoading] = useState(true); // Indicates if authentication is loading
    const [msalInstance, setMsalInstance] = useState(null); // MSAL instance for handling authentication
    const navigate = useNavigate(); // Hook for navigation

    // Initialize MSAL configuration and instance when clientIdKey is available
    useEffect(() => {
        if (clientIdKey) {
            // MSAL configuration
            const msalConfig = {
                auth: {
                    clientId: clientIdKey,
                    authority: `https://login.microsoftonline.com/${tenantIdKey}`,
                    redirectUri: `${window.location.origin}`,
                },
                cache: {
                    cacheLocation: "localStorage",
                    storeAuthStateInCookie: false,
                },
                scopes: ["openid", "profile", "user.read", `api://${clientIdKey}/Files.Read`],
            };

            // Create and set MSAL instance
            const instance = new PublicClientApplication(msalConfig);
            setMsalInstance(instance);
        }
    }, [clientIdKey, tenantIdKey]);

    // Initialize MSAL and handle redirect response
    useEffect(() => {
        const initializeMsal = async () => {
            if (msalInstance) {
                try {
                    await msalInstance.initialize(); // Initialize MSAL instance
                    const response = await msalInstance.handleRedirectPromise(); // Handle redirect response
                    if (response) {
                        console.log("MSAL Redirect Response:", response);
                        setAccount(response.account); // Set account from response
                    } else {
                        const currentAccounts = msalInstance.getAllAccounts(); // Get all accounts
                        if (currentAccounts.length === 1) {
                            setAccount(currentAccounts[0]); // Set single account
                        }
                    }
                } catch (error) {
                    console.error("Error handling redirect:", error);
                } finally {
                    setIsInitialized(true); // Set initialization state
                    setIsLoading(false); // Stop loading
                }
            }
        };

        initializeMsal();
    }, [msalInstance]);

    useEffect(() => {
        if (account) {
            // Initialize AppInsights with full account information
            appInsightsService.initializeWithAccount({
                username: account.username,
                name: account.name
            });
        }
    }, [account]);

    // Login function using popup method
    const login = () => {
        if (msalInstance) {
            setIsLoading(true); // Start loading
            msalInstance.loginPopup({
                scopes: msalInstance.getActiveAccount() ? msalInstance.getActiveAccount().scopes : [],
            }).then(response => {
                setAccount(response.account); // Set account on successful login
                localStorage.setItem("msal.access.token", response.accessToken); // Store token in session storage
                console.log("Login successful");
                navigate(defaultLoginRedirect); // Use the configurable default redirect
            }).catch(error => {
                console.error("Login error:", error);
            }).finally(() => {
                setIsLoading(false); // Stop loading
            });
        }
    };

    // Logout function using redirect method
    const logout = () => {
        if (msalInstance) {
            setIsLoading(true); // Start loading
            msalInstance.logoutRedirect({
                onRedirectNavigate: () => false, // Prevent navigation on logout
            }).then(() => {
                setAccount(null); // Clear account
                localStorage.removeItem("msal.access.token"); // Remove token from session storage
                navigate("/login"); // Navigate to login page
                console.log("Logout successful");
            }).catch(error => {
                console.error("Logout error:", error);
            }).finally(() => {
                setTimeout(() => {
                    setIsLoading(false); // Stop loading
                }, 1500);
            });
        }
    };

    // Handle potential errors from the key configuration hook
    if (keyError) {
        console.error("useAuthKeyConfig Error:", keyError);
        return <div>Error: {keyError}</div>;
    }

    // Render the MsalProvider only when msalInstance is available
    return msalInstance ? (
        <MsalProvider instance={msalInstance}>
            <AuthContext.Provider value={{ account, login, logout, isInitialized, isLoading, setIsLoading, defaultLoginRedirect }}>
                <Suspense fallback={<Loader />}>
                    {isLoading ? <Loader /> : children} {/* Show loader while loading */}
                </Suspense>
            </AuthContext.Provider>
        </MsalProvider>
    ) : (
        <Loader /> // Show loader while initializing MSAL
    );
};