import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./utils/authContext";
import PrivateRoutes from "./routes/PrivateRoutes";
import { LoadingProvider } from "./utils/loadingContext";
function App() {
    return (
        <Router>
            <AuthProvider>
                <LoadingProvider>
                    <PrivateRoutes />
                </LoadingProvider>
            </AuthProvider>
        </Router>
    );
}

export default App;