import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { getOriginalKey, decrypt } from '../utils/aesUtils';
import { securedKey } from '../utils/authContext';

const appInsightsService = {
    appInsightsInstance: null,
    isInitialized: false,
    trackingGuard: new Set(),
    GUARD_TIMEOUT: 1000,
    accountInfo: null,
    decryptedKey: null,
    initializationPromise: null,

    async decryptInstrumentationKey() {
        try {
            const originalKey = await getOriginalKey(securedKey);
            if (!originalKey) {
                throw new Error('Key retrieval failed or mismatch occurred.');
            }

            const encryptedKey = process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY;
            const decryptedKey = await decrypt(originalKey, encryptedKey);

            if (!decryptedKey) {
                throw new Error('Instrumentation key decryption failed.');
            }

            return decryptedKey;
        } catch (error) {
            console.error('Failed to decrypt instrumentation key:', error);
            return null;
        }
    },

    async initializeWithAccount(accountData) {
        if (!accountData) return;
        
        this.accountInfo = {
            username: accountData.username,
            name: accountData.name
        };
        
        if (!this.isInitialized) {
            await this.initialize();
        }
    },

    async initialize() {
        if (this.isInitialized) return;
        if (this.initializationPromise) return this.initializationPromise;

        this.initializationPromise = new Promise(async (resolve) => {
            try {
                // Decrypt the key if not already done
                if (!this.decryptedKey) {
                    this.decryptedKey = await this.decryptInstrumentationKey();
                }

                if (!this.decryptedKey) {
                    console.warn('AppInsights instrumentation key decryption failed');
                    resolve(false);
                    return;
                }

                this.appInsightsInstance = new ApplicationInsights({
                    config: {
                        instrumentationKey: this.decryptedKey,
                        enableAutoRouteTracking: false,
                        enableRequestHeaderTracking: false,
                        enableResponseHeaderTracking: false,
                        enableCorsCorrelation: false,
                        disableFetchTracking: true,
                        disableAjaxTracking: true,
                        maxBatchInterval: 0,
                        disableTelemetry: false,
                    }
                });

                this.appInsightsInstance.loadAppInsights();
                this.isInitialized = true;

                if (this.accountInfo) {
                    this.appInsightsInstance.setAuthenticatedUserContext(
                        this.accountInfo.id,
                        undefined,
                        true
                    );
                }

                resolve(true);
            } catch (error) {
                console.error('Failed to initialize AppInsights:', error);
                resolve(false);
            }
        });

        return this.initializationPromise;
    },

    trackEvent(name, properties = {}) {
        if (!this.appInsightsInstance || !name) return;

        const eventId = `${name}-${JSON.stringify(properties)}-${Date.now()}`;
        if (this.trackingGuard.has(eventId)) return;

        this.trackingGuard.add(eventId);
        setTimeout(() => this.trackingGuard.delete(eventId), this.GUARD_TIMEOUT);

        const userProperties = this.accountInfo ? {
            userEmail: this.accountInfo.username,
            userName: this.accountInfo.name
        } : {};

        this.appInsightsInstance.trackEvent(
            { name },
            { ...properties, ...userProperties }
        );
    },

    async trackApiCall(method, url, startTime, response, error = null, additionalData = {}) {
        if (!this.isInitialized) return;

        const duration = Date.now() - startTime;
        
        const userProperties = this.accountInfo ? {
            userEmail: this.accountInfo.username,
            userName: this.accountInfo.name
        } : {};

        const eventProperties = {
            endpoint: url,
            method: method.toLowerCase(),
            status: response?.status || error?.response?.status || 'unknown',
            duration: `${duration}ms`,
            success: !error,
            ...(additionalData.filters && { filters: JSON.stringify(additionalData.filters) }),
            ...userProperties
        };

        this.trackEvent('ApiCall', eventProperties);

        if (error) {
            const errorProperties = {
                endpoint: url,
                method: method.toLowerCase(),
                errorType: error.name,
                errorMessage: error.message,
                status: error.response?.status || 'unknown',
                ...userProperties
            };
            this.trackEvent('ApiError', errorProperties);
        }
    }
};

export default appInsightsService;