import React, { Suspense, lazy } from 'react';
import { Navigate } from "react-router-dom";
import { useAuth } from "../utils/authContext";
import Layout from "../layouts/Layout";
import EmptyLayout from "../layouts/EmptyLayout";
import SidebarLayout from "../layouts/SidebarLayout";
import Loader from "../components/Loader";
import { keyPart4 } from '../layouts/EmptyLayout';

// Lazy load components to optimize performance by splitting code
const Login = lazy(() => import("../pages/Login"));
const Logout = lazy(() => import("../pages/Logout"));
const Home = lazy(() => import("../pages/Home"));
const Upload = lazy(() => import("../pages/Upload"));
const BalanceRequest = lazy(() => import("../pages/BalanceRequest"));
const FlaggedProposals = lazy(() => import("../pages/FlaggedProposals/FlaggedProposals"));
const Proposals = lazy(() => import("../pages/Proposals/Proposals"));
const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard"));
const PortfolioViewOne = lazy(() => import("../pages/Dashboard/PortfolioViewOne"));
const PortfolioViewTwo = lazy(() => import("../pages/Dashboard/PortfolioViewTwo"));
const PortfolioViewThree = lazy(() => import("../pages/Dashboard/PortfolioViewThree"));
const Dmc = lazy(() => import("../pages/Dashboard/Dmc"));
const PlanSummary = lazy(() => import("../pages/Dashboard/PlanSummary"));
const CommercialOutcomes = lazy(() => import("../pages/Dashboard/CommercialOutcomes"));
const Customers = lazy(() => import("../pages/Dashboard/Customers"));
const CustomersTwo = lazy(() => import("../pages/Dashboard/CustomersTwo"));
const Customer = lazy(() => import("../pages/Dashboard/Customer"));
const PdfViewPage = lazy(() => import("../pages/PdfViewPage"));

// Component to handle redirection from the login page if the user is already authenticated
const LoginRedirect = () => {
    const { account } = useAuth(); // Access authentication context
    // If user is authenticated, redirect to the home page; otherwise, show login page
    return account ? <Navigate to="/" replace /> : <Suspense fallback={<Loader />}><Login /></Suspense>;
};

// Router configuration array
const router = [
    {
        path: "/", // Root path
        component: Layout, // Layout component for authenticated routes
        isPrivate: true, // Indicates this route requires authentication
        children: [
            {
                component: () => (
                    <Suspense fallback={<Loader />}>
                        <Home /> {/* Home page component */}
                    </Suspense>
                )
            },
        ]
    },
    {
        path: "/login", // Login path
        component: EmptyLayout, // Layout component for public routes
        isPrivate: false, // Indicates this route does not require authentication
        children: [
            {
                component: () => (
                    <Suspense fallback={<Loader />}>
                        <LoginRedirect /> {/* Handles redirection for login page */}
                    </Suspense>
                )
            }
        ]
    },
    {
        path: "/logout",
        component: EmptyLayout,
        isPrivate: false,
        children: [
            {
                component: () => (
                    <Suspense fallback={<Loader />}>
                        <Logout />
                    </Suspense>
                )
            }
        ]
    },
    {
        path: "/upload",
        component: Layout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <Suspense fallback={<Loader />}>
                        <Upload />
                    </Suspense>
                )
            }
        ]
    },
    {
        path: "/balance-request",
        component: Layout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <Suspense fallback={<Loader />}>
                        <BalanceRequest />
                    </Suspense>
                )
            }
        ]
    },
    {
        path: "/flagged-proposals",
        component: Layout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <Suspense fallback={<Loader />}>
                        <FlaggedProposals />
                    </Suspense>
                )
            }
        ]
    },
    {
        path: "/proposals",
        component: Layout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <Suspense fallback={<Loader />}>
                        <Proposals />
                    </Suspense>
                )
            }
        ]
    },
    {
        path: "/dashboard",
        component: SidebarLayout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <Suspense fallback={<Loader />}>
                        <Dashboard />
                    </Suspense>
                )
            }
        ]
    },
    {
        path: "/dashboard/portfolio1",
        component: SidebarLayout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <Suspense fallback={<Loader />}>
                        <PortfolioViewOne />
                    </Suspense>
                )
            }
        ]
    },
    {
        path: "/dashboard/portfolio2",
        component: SidebarLayout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <Suspense fallback={<Loader />}>
                        <PortfolioViewTwo />
                    </Suspense>
                )
            }
        ]
    },
    {
        path: "/dashboard/portfolio3",
        component: SidebarLayout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <Suspense fallback={<Loader />}>
                        <PortfolioViewThree />
                    </Suspense>
                )
            }
        ]
    },
    {
        path: "/dashboard/customer",
        component: SidebarLayout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <Suspense fallback={<Loader />}>
                        <Customer />
                    </Suspense>
                )
            }
        ]
    },
    {
        path: "/dashboard/dmc",
        component: SidebarLayout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <Suspense fallback={<Loader />}>
                        <Dmc />
                    </Suspense>
                )
            }
        ]
    },
    {
        path: "/dashboard/plan-summary",
        component: SidebarLayout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <Suspense fallback={<Loader />}>
                        <PlanSummary />
                    </Suspense>
                )
            }
        ]
    },
    {
        path: "/dashboard/commercial-outcomes",
        component: SidebarLayout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <Suspense fallback={<Loader />}>
                        <CommercialOutcomes />
                    </Suspense>
                )
            }
        ]
    },
    {
        path: "/dashboard/customers",
        component: SidebarLayout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <Suspense fallback={<Loader />}>
                        <Customers />
                    </Suspense>
                )
            }
        ]
    },
    {
        path: "/dashboard/customers2",
        component: SidebarLayout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <Suspense fallback={<Loader />}>
                        <CustomersTwo />
                    </Suspense>
                )
            }
        ]
    },
    {
        path: "/pdf-viewer/:dmpId",
        component: EmptyLayout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <Suspense fallback={<Loader />}>
                        <PdfViewPage />
                    </Suspense>
                )
            }
        ]
    }
];

export const keyPart3 = ":ggf"+keyPart4;

export default router;