import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "../utils/authContext";
import router from "./router";
import { keyPart9 } from "../services/axiosInstance";

// Component to handle protected routes based on authentication status
const ProtectedRoute = ({ children }) => {
    const { account } = useAuth(); // Access authentication context
    // Render children if user is authenticated; otherwise, redirect to login page
    return account ? children : <Navigate to="/login" replace />;
};

// Component that sets up private and public routes
const PrivateRoutes = () => {
    return (
        <Routes>
            {router.map((route, index) => (
                <Route
                    key={index} // Unique key for each route
                    path={route.path} // Route path
                    element={
                        route.isPrivate ? (
                            // For private routes, wrap the component with ProtectedRoute
                            <ProtectedRoute>
                                <route.component>
                                    {route.children.map((childRoute, childIndex) => (
                                        // Render child routes inside the component
                                        <childRoute.component key={childIndex} />
                                    ))}
                                </route.component>
                            </ProtectedRoute>
                        ) : (
                            // For public routes, directly render the component
                            <route.component>
                                {route.children.map((childRoute, childIndex) => (
                                    // Render child routes inside the component
                                    <childRoute.component key={childIndex} />
                                ))}
                            </route.component>
                        )
                    }
                />
            ))}
        </Routes>
    );
};

export const keyPart8 = "88" + keyPart9;

export default PrivateRoutes;